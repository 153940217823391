import React from "react";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import { BsCircleFill } from "react-icons/bs";

function Player() {
    return (
        <div className="player">
            <h2 className="emoji">
                🛩&nbsp;&nbsp;
                <BsCircleFill size="0.3em" color="red" className="blink" />
                <span className="live">&nbsp; Live</span>
            </h2>
            <p>
                I like to listen to live ATC communications. I usually listen to
                Class B or Class C airport frequencies because they are busier.
            </p>
            <h2>
                The frequency I am listening to now:{" "}
                <span className="code">KJFK Ground/Tower 1</span>
            </h2>
            <div className="code">
                KJFK 181215Z 04012KT 1SM R04R/3000VP6000FT -SN SCT000 BKN008
                OVC022 M04/M09 A3049 RMK AO2 SN SCT000 P0000 T10391089
            </div>
            <AudioPlayer
                autoPlay={false}
                src="https://s1-bos.liveatc.net/kjfk_gnd_twr?nocache=2021021807335485982"
                onPlay={(e) => console.log("Playing...")}
                showSkipControls={false}
                showJumpControls={false}
                layout="horizontal"
            />
            <span>
                Audio stream provided by{" "}
                <a href="https://www.liveatc.net/" target="blank">
                    LiveATC
                </a>
            </span>
        </div>
    );
}

export default Player;
