import React from "react";
import { Link, NavLink, useLocation } from "react-router-dom";

function Navbar(props) {
    const about = React.forwardRef((props, ref) => (
        <a
            ref={ref}
            href="/about"
            property="item"
            typeof="WebPage"
            class="menu-item"
        >
            <span property="name">About</span>
        </a>
    ));
    const projects = React.forwardRef((props, ref) => (
        <a
            ref={ref}
            href="/projects"
            property="item"
            typeof="WebPage"
            class="menu-item"
        >
            <span property="name">Projects</span>
        </a>
    ));

    const location = useLocation().pathname;

    return (
        <div class="container">
            <div class="terminal-nav">
                <header class="terminal-logo">
                    <div class="logo terminal-prompt">
                        <Link to="/">
                            noredeen.me:~{location === "/" ? "" : location}
                            {" $"}
                        </Link>
                    </div>
                </header>
                {/* <nav class="terminal-menu">
                    <ul vocab="https://schema.org/" typeof="BreadcrumbList">
                        <li property="itemListElement" typeof="ListItem">
                            <Link to="/about" component={about} />
                        </li>
                        <li property="itemListElement" typeof="ListItem">
                            <Link to="/projects" component={projects} />
                        </li>
                        <li property="itemListElement" typeof="ListItem">
                            <a
                                class="github-button"
                                href="https://github.com/noredeen-alzubi"
                                data-size="large"
                                data-show-count="true"
                                aria-label="Star Gioni06/terminal.css on GitHub"
                            ></a>
                        </li>
                    </ul>
                </nav> */}
            </div>
        </div>
    );
}

export default Navbar;
