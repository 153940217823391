import "terminal.css";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Navbar from "./components/Navbar";
import Home from "./components/home/Home";
// import About from "./components/about/About";

function App() {
    return (
        <Router>
            <Navbar />
            <Switch>
                {/* <Route path="/about">
                    <About />
                </Route>
                <Route path="/projects"></Route> */}
                <Route path="/">
                    <Home />
                </Route>
            </Switch>
        </Router>
    );
}

export default App;
