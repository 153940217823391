import React from "react";
import Hero from "./Hero";
import Player from "./Player";

function Home() {
    return (
        <>
            <Hero />
            <br />
            <br />
            <br />
            <br />
            <Player />
            <br />
        </>
    );
}

export default Home;
