import { React, ReactComponent } from "react";
import { ReactComponent as Ruby } from "../../devicons/RubyOriginal.svg";
import { ReactComponent as Rails } from "../../devicons/RailsPlainWordmark.svg";
import { ReactComponent as TS } from "../../devicons/TypescriptPlain.svg";
import { ReactComponent as Java } from "../../devicons/JavaPlain.svg";
import { ReactComponent as NodeJS } from "../../devicons/NodejsOriginal.svg";
import { ReactComponent as Python } from "../../devicons/Python.svg";
import my_avatar from "../../assets/my_avatar.jpg";
import { IoIosPaper } from "react-icons/io";
import { AiFillGithub, AiFillLinkedin } from "react-icons/ai";

function Hero() {
    return (
        <div class="hero">
            <div class="flex-parent">
                <div class="flex-l">
                    <h1 class="h1">I'm Noredeen. You can call me Noor.</h1>
                    <p>
                        I'm a (co-op) computer science student at the{" "}
                        <b>University of Waterloo</b>. I really enjoy building,
                        breaking, and fixing things.
                    </p>
                    <p>
                        <b>Daily Drivers:</b>{" "}
                        <span className="tools">
                            <span>
                                <Ruby className="devicon" />
                            </span>{" "}
                            <span>
                                <Rails className="devicon" />
                            </span>{" "}
                            <span>
                                <Python className="devicon" />
                            </span>{" "}
                            <span>
                                <TS className="devicon" />
                            </span>{" "}
                            <span>
                                <NodeJS className="devicon" />
                            </span>{" "}
                            <span>
                                <Java className="devicon" />
                            </span>{" "}
                        </span>
                    </p>
                    <br />
                    <a
                        href="https://www.linkedin.com/in/noredeen"
                        target="blank"
                        className="social"
                    >
                        <AiFillLinkedin size="1.5em" /> LinkedIn
                    </a>
                    <a
                        href="https://www.github.com/noredeen-alzubi"
                        target="blank"
                        className="social"
                    >
                        <AiFillGithub size="1.5em" /> GitHub
                    </a>
                    <a
                        href="https://www.noredeen.me/Resume.pdf"
                        target="blank"
                        className="social"
                    >
                        <IoIosPaper size="1.5em" /> Resumé
                    </a>
                </div>
                <div class="flex-r-image">
                    <img class="avatar" src={my_avatar} />
                </div>
            </div>
        </div>
    );
}

export default Hero;
